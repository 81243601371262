import { api } from "./api";
import { baseUrl } from "../config/config";

export const addStats = async data => {
  let url = baseUrl + "websites-tracking/save-websites-stats";
  let method = "POST";

  let res = await api(url, method, data);
  return res;
};
