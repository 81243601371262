<template src="./in-mob-slider.html"></template>

<script>
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import 'swiper/swiper.scss';

export default {
  name: "in-mob-slider",
  //  components: {
  //     Swiper,
  //     SwiperSlide,
  //   },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./in-mob-slider.css" scoped></style>
